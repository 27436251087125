import * as React from 'react';
// import '../sass/main.scss';

import FeatherIcon from 'feather-icons-react';
import LayoutDefault from '../layouts/default';
import TextHeader from '../components/textHeader';

// markup
const WorkListingPage = () => {
  return (
    <>
      <LayoutDefault>
        <TextHeader
          title="Our work"
          desc="A showcase of project's we've worked on in the past year"
          bottomSvgClasses="text-light"
        />
        <div>
          <section className="bg-light py-10">
            <div className="container-fluid px-5">
              <div className="row gx-5">
                <div className="col-md-6 mb-5">
                  <a className="card card-portfolio h-100" href="#!">
                    <img
                      className="card-img-top"
                      src="https://source.unsplash.com/-FVaZbu6ZAE/900x600"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="card-title">Book</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-5">
                  <a className="card card-portfolio h-100" href="#!">
                    <img
                      className="card-img-top"
                      src="https://source.unsplash.com/FUohNQatzVs/900x600"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="card-title">Business Cards</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-5">
                  <a className="card card-portfolio h-100" href="#!">
                    <img
                      className="card-img-top"
                      src="https://source.unsplash.com/eUCy1jb_B7I/900x600"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="card-title">Cactus Display</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-5">
                  <a className="card card-portfolio h-100" href="#!">
                    <img
                      className="card-img-top"
                      src="https://source.unsplash.com/sRyvf4szfMc/900x600"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="card-title">Device</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-5 mb-lg-0">
                  <a className="card card-portfolio h-100" href="#!">
                    <img
                      className="card-img-top"
                      src="https://source.unsplash.com/jipR1oTCO7U/900x600"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="card-title">White Book</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6">
                  <a className="card card-portfolio h-100" href="#!">
                    <img
                      className="card-img-top"
                      src="https://source.unsplash.com/n3sqjJzZiBM/900x600"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="card-title">Photo Face</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-white py-10">
            <div className="container px-5">
              <div className="row gx-5 justify-content-center">
                <div className="col-xl-6 col-lg-8 col-md-10 text-center py-5">
                  <h2>Ready to get Started?</h2>
                  <p className="lead text-gray-500 mb-5">
                    We'd love to hear from you about your branding and digital
                    needs. We are currently accepting new clients.
                  </p>
                  <a className="btn btn-primary fw-500" href="#!">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
            <div className="svg-border-rounded text-dark">
              {/* Rounded SVG Border*/}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 144.54 17.34"
                preserveAspectRatio="none"
                fill="currentColor"
              >
                <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
              </svg>
            </div>
          </section>
        </div>
      </LayoutDefault>
    </>
  );
};

export default WorkListingPage;
